:root {
	--color-main: #101010;
	--color-secondary: #fa8700;
}

* {
	scrollbar-width: thin;
	scrollbar-color: var(--color-secondary) transparent;
}

*::-webkit-scrollbar {
	width: 16px;
}

*::-webkit-scrollbar-track {
	background: transparent;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--color-secondary);
	border-radius: 10px;
	border: 3px solid transparent;
}

@font-face {
	font-family: 'Satoshi';
	src: url('/fonts/Satoshi-Bold.woff2') format('woff2'),
		url('/fonts/Satoshi-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Satoshi';
	src: url('/fonts/Satoshi-Medium.woff2') format('woff2'),
		url('/fonts/Satoshi-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Erode';
	src: url('/fonts/Erode-Medium.woff2') format('woff2'),
		url('/fonts/Erode-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Erode';
	src: url('/fonts/Erode-Regular.woff2') format('woff2'),
		url('/fonts/Erode-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Erode';
	src: url('/fonts/Erode-Semibold.woff2') format('woff2'),
		url('/fonts/Erode-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

html {
	min-height: 100vh;
	overflow-y: scroll;

	font-family: 'Satoshi', sans-serif;
	font-weight: 400;
	font-size: 100%;
	line-height: 120%;
	background: rgb(48, 48, 48);
	background: radial-gradient(
		circle,
		rgba(48, 48, 48, 1) 0%,
		rgba(16, 16, 16, 1) 85%
	);
	color: #fff;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	overflow-x: hidden;
}

body {
	padding: 2rem 0 2rem 0;
	@media screen and (max-width: 876px) {
		padding: 1rem 0 1rem 0;
	}
}

::selection {
	color: #000;
	background-color: var(--color-secondary);
}

/* Header */
.header {
	padding-bottom: 1.75rem;
	a {
		transition: color 0.35s ease-in-out;
		color: #fff;
		&:hover {
			color: var(--color-secondary);
			transition: color 0.35s ease-in-out;
		}
	}
	&__title {
		font-family: 'Satoshi', sans-serif;
		font-size: 3rem;
		font-weight: 800;
		line-height: 120%;
		color: #fff;
		max-width: 7ch;
		margin: 0 auto;
		text-align: center;

		@media screen and (max-width: 876px) {
			font-size: 2.5rem;
			max-width: 80%;
		}
	}

	&__overline {
		font-family: 'Erode', serif;
		font-size: 1.125rem;
		font-style: italic;
		display: block;
		padding-bottom: 1rem;
		padding-top: 0.5rem;
		@media screen and (max-width: 876px) {
			font-size: 1rem;
		}
	}
	@media screen and (max-width: 876px) {
		svg {
			height: 1.5rem;
			width: 1.5rem;
		}
	}
}

.o-line {
	height: 2.5rem;
	width: 1px;
	background: #fff;
	margin: 0 auto;
	@media screen and (max-width: 876px) {
		height: 1.5rem;
	}
	&--extended {
		height: 5rem;
		@media screen and (max-width: 876px) {
			height: 4rem;
		}
	}
}

.swipe-me-baby {
	text-align: center;
	justify-content: center;
	width: 100vw;
	min-height: 100vh;
	max-width: 100vw;
	overflow: hidden;
	padding: 0;
}

.infoText {
	font-size: 1rem;
}

.main-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.row {
	flex-direction: row !important;
}

.row > * {
	margin: 5px;
}

.swipe {
	position: absolute;
}

.swipe-nav {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	max-width: 350px;
	padding-top: 4rem;
	@media screen and (max-width: 876px) {
		max-width: 80vw;
	}
	.i-icon {
		background: var(--color-secondary);
		width: 0.55rem;
		height: 0.55rem;
		border-radius: 100%;
		padding: 0.45rem;
		display: inline-block;
		vertical-align: middle;
	}
	&__item {
		font-size: 1rem;
		padding: 1rem;
		display: flex;
		align-items: flex-start;

		&--left {
			text-align: right;
			& > span {
				padding-right: 1rem;
				svg {
					margin-top: -0.3rem;
					margin-left: -0.4rem;
				}
			}
		}
		&--right {
			text-align: left;
			& > span {
				padding-left: 1rem;
				svg {
					margin-top: -0.4rem;
					margin-left: -0.3rem;
				}
			}
		}
		& > span {
			font-family: 'Erode', serif;
			& > span {
				font-family: 'Satoshi', sans-serif;
				font-size: 0.9rem;
				letter-spacing: 0.02em;
				text-transform: uppercase;
				font-weight: 800;
				display: block;
				margin-bottom: 1rem;
			}
		}
	}
}
.text {
	width: 100%;
	max-width: 60ch;
	margin: 0 auto;
	h1 {
		padding-top: 3rem;
	}
	h2 {
		padding-top: 2rem;
	}
	ul {
		list-style-position: inside;
	}
	a {
		color: #fff;
		text-decoration: underline;
	}
}
.card {
	width: 95vw;
	max-width: 360px;
	height: 455px;
	padding: 1.5rem 0 1.5rem 0;
	cursor: pointer;
	transition: transform 0.55s ease;
	transform: scale(1);
	display: flex;
	justify-content: center;

	@media screen and (max-width: 876px) {
		width: 80vw;
		height: 420px;
	}

	&:hover {
		transform: scale(1.02);
		transition: transform 0.55s ease;
	}

	&__item {
		position: relative;
		background-color: #fff;
		border: 3px solid var(--color-main);
		width: 80vw;
		max-width: 325px;
		height: 400px;
		border-radius: 24px;
		padding: 2rem 1.2rem;

		@media screen and (max-width: 876px) {
			width: 75vw;
			height: 346px;
		}

		&-title {
			font-family: 'Erode', serif;
			font-size: 2.1rem;
			font-weight: 500;
			line-height: 105%;
			text-align: center;
			margin: 0 auto;
			letter-spacing: -0.04em;
			color: var(--color-main);
			padding: 0;
			max-width: 80%;

			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;

			@media screen and (max-width: 876px) {
				font-size: 1.95rem;
			}
		}

		&-img {
			margin: 2rem;
			border: 4px solid var(--color-secondary);
			border-radius: 100%;
			height: 200px;
			width: 200px;
			@media screen and (max-width: 876px) {
				width: 175px;
				height: 175px;
			}
		}

		&-desc {
			display: flex;
			justify-content: space-between;
		}
	}

	.desc__item {
		width: 33%;
		flex: 0 1 33%;
		position: absolute;
		bottom: 1.5rem;
		left: 1.5rem;
		width: calc(100% - 3.25rem);

		&:nth-of-type(1) {
			text-align: left;
		}

		&:nth-of-type(2) {
			text-align: center;
		}

		&:nth-of-type(3) {
			text-align: right;
		}

		h3 {
			font-family: 'Erode', serif;
			font-weight: 400;
			font-size: 1rem;
			font-style: italic;
			color: #4a4a4a;
			margin: 0;
			padding: 0;
		}

		span {
			font-size: 13px;
			font-weight: 800;
			text-transform: uppercase;
			letter-spacing: 0.04em;
			display: block;
			color: var(--color-main);
		}
	}
}

button {
	outline: 0;
	border: 0;
	background: rgb(213, 213, 213);
	height: 1.25rem;
	width: 1.25rem;
	margin-left: 0.55rem;
	cursor: pointer;
	vertical-align: top;

	svg path {
		transition: all 0.35s ease;
	}

	&:hover {
		svg path {
			stroke: var(--color-secondary);
			transition: all 0.35s ease;
		}

		color: var(--color-secondary);
		transition: all 0.35s ease;
	}
}

.btn-bookmark {
	height: 3rem;
	width: 3rem;
	border-radius: 100%;
	background: #fff;
	border: 3px solid var(--color-main);
	position: fixed;
	top: 2rem;
	right: 2rem;
	display: block;
	cursor: pointer;
	transition: background 0.35s ease-out, color 0.35s ease-out;
	z-index: 4;

	@media screen and (max-width: 876px) {
		right: 1rem;
		height: 2.5rem;
		width: 2.5rem;
		svg {
			height: 1.5rem;
			width: 1.5rem;
		}
	}

	&:hover {
		background: var(--color-secondary);
		color: #fff;
		transition: background 0.35s ease-out, color 0.35s ease-out;

		.btn-bookmark__count {
			transform: scale(1.05);
			transition: transform 0.35s ease-out, background 0.35s ease-out,
				color 0.35s ease-out;
			background: #fff;
			color: var(--color-secondary);
		}
	}

	&__inner {
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;

		span {
			margin: 0 auto;
		}
	}

	&__count {
		position: absolute;
		left: calc(50% - 0.8rem);
		bottom: -1rem;
		background: var(--color-secondary);
		border-radius: 100%;
		border: 2px solid var(--color-main);
		width: 1.5rem;
		height: 1.5rem;
		color: var(--color-main);
		font-size: 16px;
		line-height: 23px;
		font-weight: 800;
	}
}

.bookmarks {
	border-radius: 24px;
	background: rgb(213, 213, 213);
	width: 100%;
	max-width: 350px;
	position: fixed;
	border: 3px solid var(--color-main);
	top: 4rem;
	right: -700px;
	padding: 3rem 0.2rem 2rem 1rem;
	z-index: 3;
	transition: right 0.35s ease;

	&.is-visible {
		right: -1rem;
		transition: right 0.35s ease;
	}

	@media screen and (max-width: 876px) {
		&.is-visible {
			max-width: calc(100% - 3rem);
			right: -10px;
			padding: 3rem 0.2rem 2rem 2rem;
		}
	}

	h4 {
		font-size: 13px;
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: 0.04em;
		text-align: left;
		display: block;
		color: var(--color-main);
		margin: 0;
	}

	&__list {
		list-style: none;
		text-align: left;
		padding: 0 1rem 0 0;
		margin: 1rem 1rem 0 0;
		max-height: 400px;
		overflow-y: auto;
		overflow-x: hidden;

		li {
			border-bottom: 1px solid #a7a7a7;
			padding: 0.5rem 0 0.5rem 0;
			font-family: 'Erode', serif;
			font-size: 1.2rem;
			line-height: 100%;
			letter-spacing: -0.03em;
			font-weight: 500;
			color: var(--color-main);
			margin-bottom: 0.5rem;
			display: flex;
			justify-content: start;
			position: relative;
			img {
				border: 2px solid #d5d5d5;
				border-radius: 50%;
				height: 2rem;
				margin-right: 1rem;
				width: 2rem;
				z-index: 9999999;
				float: left;
			}
			a {
				color: #000;
				max-width: 90%;
				text-decoration: none;
				transition: color 0.35s ease-in-out;
				&:hover {
					color: #d57403;
					text-decoration: none;
					transition: color 0.35s ease-in-out;
				}
			}
			div {
				margin-left: auto;
			}
			div > * {
				svg path {
					transition: all 0.35s ease;
				}
			}

			div > *:hover {
				svg path {
					stroke: var(--color-secondary);
					transition: all 0.35s ease;
				}
			}
		}
	}
}

.footer {
	width: 100%;
	text-align: center;
	padding: 6rem 0 3rem 0;
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			margin-bottom: 0.5rem;
		}
	}
	a {
		text-transform: uppercase;
		font-weight: 400;
		display: block;
		color: #fff;
		text-decoration: none;
		text-align: center;
		transition: color 0.35s ease-in-out;
		&:hover {
			color: var(--color-secondary);
		}
		strong {
			font-weight: 800;
		}
	}
}
